/* so the window resize fires within a frame in IE7 */
html, body {
  height: 100%;
}

body {
  margin:0px;
  background-image:none;
  position:static;
  left:auto;
  margin-left:0;
  margin-right:0;
  text-align:left;
}

a {
  color: inherit;
}

p {
  margin: 0px;
  text-rendering: optimizeLegibility;
  font-feature-settings: "kern" 1;
  -webkit-font-feature-settings: "kern";
  -moz-font-feature-settings: "kern";
  -moz-font-feature-settings: "kern=1";
  font-kerning: normal;
}

iframe {
  background: #FFFFFF;
}

/* to match IE with C, FF */
input {
  padding: 1px 0px 1px 0px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

textarea {
  margin: 0px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

div.intcases {
  font-size: 12px;
  text-align:left; 
  border:1px solid #AAA; 
  background:#FFF none repeat scroll 0% 0%; 
  z-index:9999; 
  visibility:hidden; 
  position:absolute;
  padding: 0px;
  border-radius: 3px;
  white-space: nowrap;
}

div.intcaselink {
  cursor: pointer;
  padding: 3px 8px 3px 8px;
  margin: 5px;
  background:#EEE none repeat scroll 0% 0%; 
  border:1px solid #AAA;
  border-radius: 3px;
}

div.refpageimage {
  position: absolute;
  left: 0px;
  top: 0px;
  font-size: 0px;
  width: 16px;
  height: 16px;
  cursor: pointer;
  background-image: url('/assets/images/newwindow.gif');
  background-repeat: no-repeat;
}

div.annnoteimage {
  position: absolute;
  left: 0px;
  top: 0px;
  font-size: 0px;
  /*width: 16px;
  height: 12px;*/
  cursor: help;
  /*background-image: url(images/note.gif);*/
  /*background-repeat: no-repeat;*/
  width: 13px;
  height: 12px;
  padding-top: 1px;
  text-align: center;
  background-color: #138CDD;
  -moz-box-shadow: 1px 1px 3px #aaa;
  -webkit-box-shadow: 1px 1px 3px #aaa;
  box-shadow: 1px 1px 3px #aaa;
}

div.annnoteline {
  display: inline-block;
  width: 9px;
  height: 1px;
  border-bottom: 1px solid white;
  margin-top: 1px;
}

div.annnotelabel {
  position: absolute;
  left: 0px;
  top: 0px;
  font-family: Helvetica,Arial,sans-serif; 
  font-size: 10px;
  /*border: 1px solid rgb(166,221,242);*/
  cursor: help;
  /*background:rgb(0,157,217) none repeat scroll 0% 0%;*/ 
  padding: 1px 3px 1px 3px;
  white-space: nowrap;
  color: white;
  
  background-color: #138CDD;
  -moz-box-shadow: 1px 1px 3px #aaa;
  -webkit-box-shadow: 1px 1px 3px #aaa;
  box-shadow: 1px 1px 3px #aaa;
}

.annotation {
  font-size: 12px;
  padding-left: 2px;
  margin-bottom: 5px;
}

.annotationName {
  /*font-size: 13px;
  font-weight: bold;
  margin-bottom: 3px;
  white-space: nowrap;*/

  font-family:'Trebuchet MS','sans-serif';
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
  white-space: nowrap;
}

.annotationValue {
  font-family: Arial, Helvetica, Sans-Serif;
  font-size: 12px;
  color: #4a4a4a;
  line-height: 21px;
  margin-bottom: 20px;
}

.noteLink {
  text-decoration: inherit;
  color: inherit;
}

.noteLink:hover {
  background-color: white;
}

/* this is a fix for the issue where dialogs jump around and takes the text-align from the body */
.dialogFix {
  position:absolute;
  text-align:left;
  border: 1px solid #8f949a;
}


@keyframes pulsate {
from {
  box-shadow: 0 0 10px #15d6ba;
}
to {
  box-shadow: 0 0 20px #15d6ba;
}
}

@-webkit-keyframes pulsate {
from {
  -webkit-box-shadow: 0 0 10px #15d6ba;
  box-shadow: 0 0 10px #15d6ba;
}
to {
  -webkit-box-shadow: 0 0 20px #15d6ba;
  box-shadow: 0 0 20px #15d6ba;
}
}

@-moz-keyframes pulsate {
from {
  -moz-box-shadow: 0 0 10px #15d6ba;
  box-shadow: 0 0 10px #15d6ba;
}
to {
  -moz-box-shadow: 0 0 20px #15d6ba;
  box-shadow: 0 0 20px #15d6ba;
}
}

.legacyPulsateBorder {
  /*border: 5px solid #15d6ba;
  margin: -5px;*/
  -moz-box-shadow: 0 0 10px 3px #15d6ba;
  box-shadow: 0 0 10px 3px #15d6ba;
}

.pulsateBorder {
animation-name: pulsate;
animation-timing-function: ease-in-out;
animation-duration: 0.9s;
animation-iteration-count: infinite;
animation-direction: alternate;

-webkit-animation-name: pulsate;
-webkit-animation-timing-function: ease-in-out;
-webkit-animation-duration: 0.9s;
-webkit-animation-iteration-count: infinite;
-webkit-animation-direction: alternate;

-moz-animation-name: pulsate;
-moz-animation-timing-function: ease-in-out;
-moz-animation-duration: 0.9s;
-moz-animation-iteration-count: infinite;
-moz-animation-direction: alternate;
}

.ax_default_hidden, .ax_default_unplaced{
  display: none;
  visibility: hidden;
}

.widgetNoteSelected {
  -moz-box-shadow: 0 0 10px 3px #138CDD;
  box-shadow: 0 0 10px 3px #138CDD;
  /*-moz-box-shadow: 0 0 20px #3915d6;
  box-shadow: 0 0 20px #3915d6;*/
  /*border: 3px solid #3915d6;*/
  /*margin: -3px;*/
}
.singleImg {
  display: none;
  visibility: hidden;
}
.ax_default {
  
  font-weight:400;
  font-style:normal;
  font-size:13px;
  color:#333333;
  text-align:center;
  line-height:normal;
}
.heading_1 {
  
  font-weight:bold;
  font-style:normal;
  font-size:32px;
  text-align:left;
}
.heading_2 {
  
  font-weight:bold;
  font-style:normal;
  font-size:24px;
  text-align:left;
}
.heading_3 {
   
  font-weight:bold;
  font-style:normal;
  font-size:18px;
  text-align:left;
}
.heading_4 {
  
  font-weight:bold;
  font-style:normal;
  font-size:14px;
  text-align:left;
}
.heading_5 {
  
  font-weight:bold;
  font-style:normal;
  text-align:left;
}
.heading_6 {
  
  font-weight:bold;
  font-style:normal;
  font-size:10px;
  text-align:left;
}
.paragraph {
  text-align:left;
}

.text-h1 {
  font-size:32px;
  color:#63666A;
  text-align:left;
  line-height:32px;
}

.sticky_1 {
  text-align:left;
}
.text-paragraph {
  font-size:16px;
  color:#282A2E;
  text-align:left;
  line-height:24px;
}
.text_link {
  color:#005187;
}
.text_link_mouse_over {
  color:#005187;
}

.shape {
  
  font-weight:normal;
  font-style:normal;
  font-size:12px;
  color:#333333;
  text-align:left;
  line-height:normal;
}
.image {
  font-size:12px;
  color:#323335;
}
.icon-font {
  font-family:'Optum-Icon-Font Regular', 'Optum-Icon-Font','sans-serif';
  font-weight:400;
  font-style:normal;
  font-size:16px;
  color:#C25608;
  text-align:center;
}

.button-primary {
  font-size:16px;
  color:#282A2E;
  text-align:center;
}
.button-mouseover {
  font-size:16px;
}
.button-selected {
  font-size:16px;
}
.button-disabled {
  font-size:16px;
  color:#B1B2B3;
  text-align:center;
}
.button_with_icon_-_normal {
  font-size:16px;
  color:#282A2E;
  text-align:left;
}
.callout {
  
  font-weight:bold;
  font-style:normal;
  color:#FFFFFF;
  text-align:center;
  line-height:normal;
}
.box_1 {
  font-size:16px;
  text-align:left;
}
.text-paragraph-small {
  font-size:14px;
  color:#282A2E;
  text-align:left;
  line-height:24px;
}
.global-utility-nav-item {
  font-size:14px;
  color:#FFFFFF;
  text-align:left;
}

.button-normal {
  font-size:16px;
  color:#282A2E;
  text-align:center;
}
.shape1 {
  font-size:12px;
}
.nav-item-icon-left {
  font-size:16px;
  color:#282A2E;
  text-align:center;
}
.nav-item-icon-left-active {
  font-size:16px;
}
.shape2 {
  
  font-weight:normal;
  font-style:normal;
  font-size:13px;
  color:#333333;
  text-align:left;
  line-height:normal;
}
.nav-secondary-flyout-item {
  font-size:16px;
  text-align:left;
}
.modal-border-overlay {
  
  font-weight:normal;
  font-style:normal;
  font-size:12px;
  color:#333333;
  text-align:left;
  line-height:normal;
}

.modal-box {
  
  font-weight:normal;
  font-style:normal;
  font-size:12px;
  color:#333333;
  text-align:left;
  line-height:normal;
}
.modal-header {
  
  font-weight:bold;
  font-style:normal;
  font-size:16px;
  color:#282A2E;
  text-align:left;
  line-height:normal;
}
.button-mousedown {
  font-size:16px;
}
.button-link {
  font-size:16px;
  color:#005187;
  text-align:left;
}
.button-link-mouseover {
  font-size:16px;
  color:#005187;
  text-align:left;
}
.button-link-mousedown {
  font-size:16px;
  color:#005187;
  text-align:left;
}
.button-link-selected {
  font-size:16px;
  color:#005187;
  text-align:left;
}
.button-link-disabled {
  font-size:16px;
  color:#B1B2B3;
  text-align:left;
}
.table-cell {
  font-size:16px;
  text-align:left;
  line-height:20px;
}
.text-label {
  
  font-weight:700;
  font-style:normal;
  font-size:16px;
  color:#282A2E;
  text-align:left;
  line-height:16px;
}
.note-instructions {
  
  font-weight:400;
  font-style:normal;
  font-size:12px;
  color:#333333;
  text-align:left;
  line-height:normal;
}
.table-header-with-filter {
  font-size:16px;
  color:#282A2E;
  text-align:left;
  line-height:20px;
}

.droplist {
  color:#282A2E;
  text-align:left;
}
.text_field {
  font-size:16px;
  text-align:left;
}
.link {
  font-size:16px;
  color:#005187;
  text-align:left;
  line-height:normal;
}
.link-mouseover {
  font-size:16px;
}
.link-disabled {
  font-size:16px;
  color:#999999;
}
.table-header {
  font-size:16px;
  color:#282A2E;
  text-align:left;
  line-height:20px;
}
.nav-secondary-bar {
  
  font-weight:normal;
  font-style:normal;
  font-size:12px;
  color:#333333;
  text-align:center;
  line-height:normal;
}
.nav-secondary-item {
  font-size:16px;
  color:#282A2E;
  text-align:center;
}

.nav-secondary-item-active {
  text-align:center;
}
.icon-font-button-disabled {
  color:#999999;
}
.text-helper {
  font-size:16px;
  color:#63666A;
  text-align:left;
  line-height:24px;
}
.nav-secondary-item-icon-right {
  font-size:16px;
  text-align:center;
}

.nav-secondary-item-icon-right-active {
  text-align:center;
}
.nav-secondary-flyout-item-hover {
  font-size:16px;
  text-align:left;
}
.droplist-option-multiselect-hover {
  text-align:left;
}
.nav-secondary-flyout-item-selected {
  font-size:16px;
  text-align:left;
}

.text-field-noborder {
  
  font-weight:400;
  font-style:normal;
  font-size:12px;
  text-align:left;
}

.field-border-active {
  
  font-weight:normal;
  font-style:normal;
  font-size:12px;
  color:#333333;
  text-align:left;
  line-height:normal;
}

.error-validation-message {
  font-size:16px;
  text-align:left;
}

.chart-label-y-axis {
  
  font-weight:normal;
  font-style:normal;
  font-size:16px;
  color:#666666;
  text-align:right;
}
.chart-label-x-axis {
  
  font-weight:normal;
  font-style:normal;
  font-size:16px;
  color:#666666;
  text-align:center;
}
.chart-text-source {
  
  font-weight:normal;
  font-style:normal;
  color:#666666;
  text-align:left;
}
.chart-text-legend {
  
  font-weight:normal;
  font-style:normal;
  color:#666666;
  text-align:left;
}
.text-field-noborder1 {
  
  font-weight:400;
  font-style:normal;
  font-size:16px;
  text-align:left;
}

.image2 {
  font-size:12px;
  color:#323335;
}
.required {
  font-size:16px;
  color:#E32315;
  text-align:left;
  line-height:16px;
}
.button_with_icon_-_primary {
  font-size:16px;
  color:#282A2E;
  text-align:center;
}
.button_with_icon_-_mouseover {
  font-size:16px;
}
.button_with_icon_-_mousedown {
  font-size:16px;
}
.button_with_icon_-_selected {
  font-size:16px;
}
.button_with_icon_-_disabled {
  font-size:16px;
  color:#B1B2B3;
  text-align:center;
}

.field-border-active1 {
  
  font-weight:normal;
  font-style:normal;
  font-size:12px;
  color:#333333;
  text-align:left;
  line-height:normal;
}
.tab {
  
  font-weight:400;
  font-style:normal;
  font-size:16px;
}

.tab-disabled {
  font-size:16px;
  color:#999999;
}
.panel-box {
  font-size:12px;
  text-align:left;
}
.chart-label-y-axis1 {
  
  font-weight:400;
  font-style:normal;
  color:#666666;
  text-align:right;
}

.tooltip {
  
  font-weight:normal;
  font-style:normal;
  font-size:12px;
  color:#333333;
  text-align:left;
  line-height:normal;
}
.text-h5 {
  
  font-weight:bold;
  font-style:normal;
  font-size:16px;
  color:#282A2E;
  text-align:left;
}

.pie-chart-label {
  
  font-weight:normal;
  font-style:normal;
  color:#666666;
  text-align:center;
}
.shape3 {
  
  font-weight:normal;
  font-style:normal;
  font-size:12px;
  color:#333333;
  text-align:left;
  line-height:normal;
}
.flyout-menu-item {
  
  font-weight:400;
  font-style:normal;
  font-size:12px;
  color:#282A2E;
}

.text-h2 {
  
  font-weight:bold;
  font-style:normal;
  font-size:24px;
  color:#C25608;
  text-align:left;
  line-height:24px;
}
.text-h3 {
  
  font-weight:bold;
  font-style:normal;
  font-size:20px;
  color:#282A2E;
  text-align:left;
}

.text-paragraph-xsmall {
  font-size:12px;
  color:#282A2E;
  text-align:left;
  line-height:24px;
}
.label {
  
  font-weight:bold;
  font-style:normal;
  color:#333333;
}
.nav-vertical-item-with-icon {

  font-weight:normal;
  font-style:normal;
  font-size:16px;
  color:#333333;
  text-align:left;
  line-height:normal;
}
.nav-item {
  font-size:16px;
  color:#282A2E;
  text-align:center;
}
.nav-item-active {
  font-size:16px;
}

.droplist1 {
  font-size:16px;
  color:#282A2E;
  text-align:left;
}
.link-with-icon {
  font-size:16px;
  color:#005187;
  text-align:left;
  line-height:20px;
}
.modal-box1 {
  
  font-weight:normal;
  font-style:normal;
  font-size:12px;
  color:#333333;
  text-align:left;
  line-height:normal;
}
.table-cell-link {
  font-size:16px;
  color:#005187;
  text-align:left;
  line-height:20px;
}

.table-row-disabled {
  color:#B1B2B3;
}

.panel-header-h2 {
  
  font-weight:700;
  font-style:normal;
  font-size:20px;
  color:#C25608;
  text-align:left;
}
.field-border-active2 {
  
  font-weight:normal;
  font-style:normal;
  font-size:12px;
  color:#333333;
  text-align:left;
  line-height:normal;
}

.heading_11 {
  font-family:'Arial Bold', 'Arial';
  font-weight:700;
  font-style:normal;
  font-size:32px;
  text-align:left;
}
.text-h4 {
  
  font-weight:bold;
  font-style:normal;
  font-size:18px;
  color:#63666A;
  text-align:left;
}
.chart-label-x-axis1 {
  
  font-weight:400;
  font-style:normal;
  color:#666666;
  text-align:center;
}
.radio_button {
  font-size:16px;
  color:#282A2E;
  text-align:left;
}
.table-header1 {
  color:#282A2E;
}

.text_field1 {
  font-size:12px;
  text-align:left;
}
.link-with-icon1 {
  font-size:12px;
  color:#005187;
  text-align:left;
  line-height:20px;
}
.link-mouseover1 {
  font-size:12px;
}
.link-disabled1 {
  font-size:12px;
  color:#999999;
}
.table-cell1 {
  font-size:12px;
  text-align:left;
}

.link-with-icon2 {
  color:#005187;
  text-align:left;
  line-height:20px;
}
.link-disabled2 {
  color:#999999;
}
.text-paragraph1 {
  font-size:12px;
  color:#282A2E;
  text-align:left;
  line-height:18px;
}
.button-normal1 {
  font-size:12px;
  color:#282A2E;
  text-align:center;
}
.button-mouseover1 {
  font-size:12px;
}
.button-selected1 {
  font-size:12px;
}
.button-disabled1 {
  font-size:12px;
  color:#B1B2B3;
  text-align:center;
}
.link1 {
  font-size:12px;
  color:#005187;
  text-align:left;
  line-height:18px;
}
.checkbox {
  color:#282A2E;
  text-align:left;
}

.droplist-option {
  text-align:left;
}
.droplist-option-hover {
  text-align:left;
}
.droplist-option-selected {
  color:#000000;
  text-align:left;
}
.droplist-option-disabled {
  color:#999999;
  text-align:left;
}
.modal-header1 {
  
  font-weight:bold;
  font-style:normal;
  font-size:16px;
  color:#282A2E;
  text-align:left;
  line-height:normal;
}
.button-primary1 {
  font-size:12px;
  color:#282A2E;
  text-align:center;
}
.button-mousedown1 {
  font-size:12px;
}
.text_field2 {
  color:#000000;
  text-align:left;
}
.radio_button1 {
  color:#282A2E;
  text-align:left;
}
.table-cell-link1 {
  font-size:12px;
  color:#005187;
  text-align:left;
}
.table-cell-link-hover {
  font-size:12px;
  color:#005187;
  text-align:left;
}
.image3 {
  font-size:12px;
  color:#323335;
}
.text-paragraph2 {
  font-size:12px;
  color:#282A2E;
  text-align:left;
  line-height:18px;
}

.text-value {
  font-size:16px;
  color:#282A2E;
  text-align:left;
  line-height:20px;
}
.droplist-option1 {
  font-size:16px;
  text-align:left;
}

.global-utility-nav-item1 {
  font-size:12px;
  color:#FFFFFF;
  text-align:left;
}
.modal-overlay {
  font-size:12px;
}
.text-h11 {
  font-size:24px;
  color:#63666A;
  text-align:left;
}
.text-label1 {
  
  font-weight:700;
  font-style:normal;
  font-size:12px;
  color:#282A2E;
  text-align:left;
  line-height:18px;
}
.button-normal2 {
  font-size:12px;
  color:#282A2E;
  text-align:center;
}
.button-mouseover2 {
  font-size:12px;
}
.button-selected2 {
  font-size:12px;
}
.button-disabled2 {
  font-size:12px;
  color:#B1B2B3;
  text-align:center;
}
.button-link1 {
  font-size:12px;
  color:#005187;
  text-align:left;
}
.button-link-mouseover1 {
  font-size:12px;
  color:#005187;
  text-align:left;
}
.button-link-mousedown1 {
  font-size:12px;
  color:#005187;
  text-align:left;
}
.button-link-selected1 {
  font-size:12px;
  color:#005187;
  text-align:left;
}
.button-link-disabled1 {
  font-size:12px;
  color:#B1B2B3;
  text-align:left;
}
.text-helper1 {
  color:#63666A;
  text-align:left;
}
.tooltip1 {
  
  font-weight:normal;
  font-style:normal;
  font-size:12px;
  color:#333333;
  text-align:left;
  line-height:normal;
}
.text-value1 {
  color:#282A2E;
  text-align:left;
  line-height:18px;
}
.text-h6 {
  
  font-weight:700;
  font-style:normal;
  font-size:16px;
  color:#C25608;
  text-align:left;
}
.dropdown-css{
  width: 100%;
}

g.tick text{
  
  font-weight: 700;
  font-style: normal;
  color: #808080;
}


/* UI Toolkit upgrade changes */
uitk-dropdown .uitk-c-select > span:not(.uitk-u-element--hidden) {
  display: block;
  height : 18px;
  overflow: hidden;
}
uitk-dropdown  .uitk-c-dropdown--expanded {
  overflow-y: hidden;
}

.uitk-c-select_text-wrap{
  white-space: nowrap;
  text-overflow: unset;

}

.uitk-c-alert__container--top-right{
  z-index: 1800;
  top: 20px;
}
.uitk-c-component-load-indicator__overlay{
  top:0px;
  left:0px;
} 
.uitk-c-table__head{
  white-space: normal;
}
.empty-cnt{
  min-height: 60vh;
}
.uitk-c-table tr td,.uitk-c-table th{
  font-size:13px;
}
table .uitk-c-table__row > td,table .uitk-c-table__expandable-row > td{
  padding: 0.5rem 0.75rem !important;
}

table .uitk-c-table__expandable-row > td.icon-td{
  padding: 0.5rem 5px !important;
}
.text-record.m-l-10{
  white-space: nowrap;
}
body .uitk-c-table__head > tr > th{
  vertical-align: bottom;
}

.uitk-c-table__head > tr > th {
  position: relative;
}
body .uitk-c-select_text-wrap  {
  white-space: nowrap;
  text-overflow: unset;

}
.records-count-text{
  font-weight: 700;
}

label.uitk-c-pagination__go-to-label{
  margin-bottom:0px;
}
.uitk-c-pagination__go-to{
  gap: 4px;
}
.uitk-c-pagination__buttons{
  gap: 5px;
}